import naja from 'naja';

if (!('IntersectionObserver' in window)) {
	require('intersection-observer-amd');
}

class Faq {
	constructor() {
		this.questions = document.querySelectorAll('.faq-question');
	}

	initialize() {
		this.questions.forEach(item => {
			item.addEventListener('click', () => {
				const answer = item.nextElementSibling;

				this.questions.forEach(otherItem => {
					if (otherItem !== item) {
						otherItem.classList.remove('open');
						otherItem.setAttribute('aria-expanded', 'false');
					}
				});

				// Zavření všech ostatních odpovědí a aktualizace jejich ARIA atributů
				document
					.querySelectorAll('.faq-answer')
					.forEach(otherAnswer => {
						if (otherAnswer !== answer) {
							otherAnswer.classList.remove('open');
							otherAnswer.style.maxHeight = null;
							otherAnswer.previousElementSibling.setAttribute(
								'aria-expanded',
								'false',
							);
							otherAnswer.setAttribute('aria-hidden', 'true');
						}
					});

				// Přepnutí třídy 'open' a nastavení max-height pro animaci
				if (answer.classList.contains('open')) {
					answer.style.maxHeight = null;
					item.setAttribute('aria-expanded', 'false');
					answer.setAttribute('aria-hidden', 'true');
				} else {
					answer.style.maxHeight =
						answer.scrollHeight + 16 + 16 + 'px';
					item.setAttribute('aria-expanded', 'true');
					answer.setAttribute('aria-hidden', 'false');
				}
				answer.classList.toggle('open');
				item.classList.toggle('open');
			});
		});
	}
}

naja.registerExtension(new Faq());
