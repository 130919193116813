import naja from 'naja';
import onDomReady from '../onDomReady';

if (!('IntersectionObserver' in window)) {
	require('intersection-observer-amd');
}

class MainMenuDropdown {
	constructor() {}

	initialize(naja) {
		onDomReady(() => {
			const dropdownButton =
				document.getElementById('dropdownMenuButton');

			if (dropdownButton) {
				const dropdownList = document.querySelector(
					'.main-menu__dropdown-list',
				);

				dropdownButton.addEventListener('click', () => {
					dropdownList.classList.toggle('is-active');
				});

				document.addEventListener('click', event => {
					if (
						!dropdownButton.contains(event.target) &&
						!dropdownList.contains(event.target)
					) {
						dropdownList.classList.remove('is-active');
					}
				});

				const dropdownLinks = document.querySelectorAll(
					'.main-menu__dropdown-link',
				);
				dropdownLinks.forEach(link => {
					link.addEventListener('click', () => {
						dropdownList.classList.remove('is-active');
					});
				});
			}
		});
	}
}

naja.registerExtension(new MainMenuDropdown());
